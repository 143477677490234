// extracted by mini-css-extract-plugin
export var topImg = "_top-module--topImg--2aBc1";
export var topTxt = "_top-module--topTxt--zCwsH";
export var ttlBg = "_top-module--ttlBg--4+K9s";
export var ttlBg2 = "_top-module--ttlBg2--aEv4T";
export var border = "_top-module--border--dO4RU";
export var headingEnWrapper = "_top-module--headingEnWrapper--qENF1";
export var headingEn = "_top-module--headingEn--83aY9";
export var headingJp = "_top-module--headingJp--uMdqa";
export var serviceWrapper = "_top-module--serviceWrapper--he1Yv";
export var prodcutsWrapper = "_top-module--prodcutsWrapper--7KQwq";
export var recruitWrapper = "_top-module--recruitWrapper--rX8zV";
export var serviceMenuWrapper = "_top-module--serviceMenuWrapper--NpTWE";
export var productMenuWrapper = "_top-module--productMenuWrapper--+PMMd";
export var txtContent = "_top-module--txtContent--YKzch";
export var topBrdBlue = "_top-module--topBrdBlue--t-alP";
export var linkBtn = "_top-module--linkBtn--zUcDc";
export var aboutWrapper = "_top-module--aboutWrapper--652OW";
export var aboutTtl = "_top-module--aboutTtl--ol0fS";
export var txtLightblue = "_top-module--txtLightblue--fIGFV";
export var txtBlue = "_top-module--txtBlue--Meyd4";
export var aboutTxt = "_top-module--aboutTxt--9JXEd";
export var serviceMenu = "_top-module--serviceMenu--HjYjp";
export var serviceIcon = "_top-module--serviceIcon--vob8M";
export var productMenu = "_top-module--productMenu--ibWOP";
export var productIcon = "_top-module--productIcon--K0Zi6";
export var locationListTtl = "_top-module--locationListTtl--xfxFT";
export var locationList = "_top-module--locationList--REl7R";
export var sliderImg = "_top-module--sliderImg--BuUz2";