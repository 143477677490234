import * as React from "react"
import Slider from "react-slick";

import Layout from "src/components/layout"
import Seo from "src/components/seo"

import * as styles from "src/scss/_top.module.scss"
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import Pos from "src/images/pos-system.png"
import Intergration from "src/images/system-integration.png"
import Web from "src/images/web-system.png"
import Denpyo from "src/images/label-printer.png"
import InfoPos from "src/images/cash-register.png"
import ninno from "src/images/recruit/ninno1.jpg"
import lab1 from "src/images/recruit/lab-1.jpg"
import lab3 from "src/images/recruit/lab-3.jpg"
import lab4 from "src/images/recruit/lab-4.jpg"
import lab6 from "src/images/recruit/lab-6.jpg"
import lab7 from "src/images/recruit/lab-7.jpg"
import lab8 from "src/images/recruit/lab-8.jpg"
import hfItems from "src/images/recruit/hf-items.jpg"
import labFront1 from "src/images/recruit/lab-front1.jpg"
import tokamachi1 from "src/images/recruit/tokamachi1.jpg"
import tokamachi2 from "src/images/recruit/tokamachi2.jpg"
import tokamachi3 from "src/images/recruit/tokamachi3.jpg"
import tokamachi4 from "src/images/recruit/tokamachi4.jpg"
import nagaoka1 from "src/images/recruit/nagaoka1.jpg"
import nagaoka2 from "src/images/recruit/nagaoka2.jpg"
import nagaoka3 from "src/images/recruit/nagaoka3.jpg"


export default function IndexPage () {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 4000,
    arrows: false
  };
  return (
    <Layout>
      <Seo title="" />
      <div className={styles.topImg}>
        <div className={styles.topTxt}>
          <h1>WE DELIVER THE SOFTWARE YOU NEED.</h1>
          <p>新しいチャレンジをし続ける。</p>
        </div>
      </div>

      <section className={styles.ttlBg}>
        <div className={`${styles.border} ${styles.headingEnWrapper}`}>
          <h3 className={styles.headingEn}>ABOUT US</h3>
        </div>
        <p className={styles.headingJp}>会社概要</p>
      </section>

      <div className={styles.aboutWrapper}>
        <p className={styles.aboutTtl}>"<span className={styles.txtBlue}>Re</span>"<span className={styles.txtLightblue}>NK CHANNEL</span>とは</p>
        <p className={styles.aboutTxt}>
          ハードオフグループが掲げる店舗とお客様、そして働く社員とスタッフを繋ぐシステムを創り上げるため各チャネル間での繋がりを強化し、
          それぞれのチャネルへの流入を増加させることが
          <span> 「”Re”NK CHANNEL構想」</span>
          その実現のため誕生したのがリンクチャネル株式会社です。
          わたしたちは、システムを通じて利用する方に寄り添い新しい未来を創りたいという思いを持ち
          開発や運営保守を行ってまいります。
        </p>
      </div>

      <section className={styles.ttlBg2}>
        <div className={`${styles.border} ${styles.headingEnWrapper}`}>
          <h3 className={styles.headingEn}>SERVICES</h3>
        </div>
        <p className={styles.headingJp}>事業概要</p>
      </section>

      <div className={styles.serviceWrapper}>
        <div className={styles.serviceMenuWrapper}>
          <div className={styles.serviceMenu}>
            <div className={styles.serviceIcon}>
              <img src={ Pos } alt="" />
              <p>POSシステム開発</p>
            </div>
            <p className={styles.txtContent}>
              POSシステムの豊富な経験と実績でお客様のご要望にお答えします。個別受託開発、パッケージ開発、パッケージOEM開発等、POSに関する開発はお任せ下さい。
            </p>
          </div>
          <hr className={styles.topBrdBlue} />
          <div className={styles.serviceMenu}>
            <div className={styles.serviceIcon}>
              <img src={ Intergration } alt="" />
              <p>システムインテグレーション</p>
            </div>
            <p className={styles.txtContent}>
              POSシステム、店舗情報システム、製造設備システム、計測制御システムなどのシステムインテグレーションを手掛けております。
            </p>
          </div>
          <hr className={styles.topBrdBlue} />
          <div className={styles.serviceMenu}>
            <div className={styles.serviceIcon}>
              <img src={ Web } alt="" />
              <p>WEBシステム</p>
            </div>
            <p className={styles.txtContent}>
              ショッピングシステム、顧客サービスシステム、予約システムなどサーバサイドアプリケーションの開発を手掛けております。
            </p>
          </div>
        </div>
        <div className={styles.linkBtn}>
          <a href="/services/">事業について詳しく見る
            <svg xmlns="http://www.w3.org/2000/svg"  height="30px" viewBox="0 0 30 30" width="30px" ><rect fill="none" height="30" width="30"/><path d="M15,5l-1.41,1.41L18.17,11H2V13h16.17l-4.59,4.59L15,19l7-7L15,5z"/></svg>
          </a>
        </div>
      </div>

      <section className={styles.ttlBg}>
        <div className={`${styles.border} ${styles.headingEnWrapper}`}>
          <h3 className={styles.headingEn}>PRODUCTS</h3>
        </div>
        <p className={styles.headingJp}>製品概要</p>
      </section>

      <div className={styles.prodcutsWrapper}>
        <div className={styles.productMenuWrapper}>
          <div className={styles.productMenu}>
            <div className={styles.productIcon}>
              <img src={ Denpyo } alt="" />
              <p>インフォ・ザ・伝票</p>
            </div>
            <p className={styles.txtContent}>
            インフォ・ザ・伝票は、専用伝票などの記入項目をドットインパクトプリンターで印刷するソフトです。
            </p>
          </div>
          <hr className={styles.topBrdBlue} />
          <div className={styles.productMenu}>
            <div className={styles.productIcon}>
              <img src={ InfoPos } alt="" />
              <p>Info-POS</p>
            </div>
            <p className={styles.txtContent}>
            Info-POSは主に物販向けの汎用POSレジソフトです。
            </p>
          </div>
        </div>
        <div className={styles.linkBtn}>
          <a href="/online-store/">製品購入ページへ
            <svg xmlns="http://www.w3.org/2000/svg"  height="30px" viewBox="0 0 30 30" width="30px" ><rect fill="none" height="30" width="30"/><path d="M15,5l-1.41,1.41L18.17,11H2V13h16.17l-4.59,4.59L15,19l7-7L15,5z"/></svg>
          </a>
        </div>
      </div>

      <section className={styles.ttlBg2}>
        <div className={`${styles.border} ${styles.headingEnWrapper}`}>
          <h3 className={styles.headingEn}>RECRUIT</h3>
        </div>
        <p className={styles.headingJp}>採用情報</p>
      </section>

      <div className={styles.recruitWrapper}>
        <p className={styles.locationListTtl}>
          <span></span>募集部署
        </p>
        <div className={styles.locationList}>
          <p>新潟県十日町市</p>
          <p>・十日町開発センター</p>
        </div>
        <div className={styles.locationList}>
          <p>新潟県長岡市</p>
          <p>・長岡CSセンター</p>
        </div>
        <div className={styles.locationList}>
          <p>新潟県新潟市</p>
          <p>・ハードオフ未来ラボ</p>
        </div>

        <div className={styles.linkBtn}>
          <a href="/recruit/">採用情報について詳しく見る
            <svg xmlns="http://www.w3.org/2000/svg"  height="30px" viewBox="0 0 30 30" width="30px" ><rect fill="none" height="30" width="30"/><path d="M15,5l-1.41,1.41L18.17,11H2V13h16.17l-4.59,4.59L15,19l7-7L15,5z"/></svg>
          </a>
        </div>

        {/* スライダーの写真の大きさは900px x 600px または３：２で作成する*/}

        <Slider {...settings} className={styles.sliderImg}>
          <div>
            <h3><img src={ hfItems } alt="ハードオフグッズ" /></h3>
          </div>
          <div>
            <h3><img src={ labFront1 } alt="ハードオフ未来ラボ入り口" /></h3>
          </div>
          <div>
            <h3><img src={ tokamachi1 } alt="十日町開発センター入り口" /></h3>
          </div>
          <div>
            <h3><img src={ nagaoka3 } alt="長岡CSセンター入り口" /></h3>
          </div>
          <div>
            <h3><img src={ ninno } alt="未来ラボの様子" /></h3>
          </div>
          <div>
            <h3><img src={ tokamachi3 } alt="十日町開発センターの様子" /></h3>
          </div>
          <div>
            <h3><img src={ lab1 } alt="未来ラボの様子" /></h3>
          </div>
          <div>
            <h3><img src={ nagaoka2 } alt="長岡CSセンターの様子" /></h3>
          </div>
          <div>
            <h3><img src={ lab3 } alt="未来ラボの様子" /></h3>
          </div>
          <div>
            <h3><img src={ tokamachi4 } alt="十日町開発センターの様子" /></h3>
          </div>
          <div>
            <h3><img src={ lab4 } alt="未来ラボの様子" /></h3>
          </div>
          <div>
            <h3><img src={ nagaoka1 } alt="長岡CSセンターの様子" /></h3>
          </div>
          <div>
            <h3><img src={ lab6 } alt="未来ラボの様子" /></h3>
          </div>
          <div>
            <h3><img src={ tokamachi2 } alt="十日町開発センターの様子" /></h3>
          </div>
          <div>
            <h3><img src={ lab7 } alt="未来ラボの様子" /></h3>
          </div>
          <div>
            <h3><img src={ lab8 } alt="未来ラボの様子" /></h3>
          </div>
        </Slider>
      </div>
    </Layout>
  );
}
